@import url(https://use.fontawesome.com/releases/v5.15.1/css/all.css);
@import url(https://use.fontawesome.com/releases/v5.15.1/css/all.css);
html {
  height: 100%;
}

body {
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
  font-family: 'Raleway Bold', 'Raleway Regular', 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'lnum';
  -webkit-font-feature-settings: 'lnum';
  -moz-osx-font-feature-settings: 'lnum';
  height: 100%;
}
body.private {
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.wrapNav{padding:22px 0;display:flex}@media(max-width: 640px){.wrapNav .wrapButtons~button{margin:0 !important}}.linkDefault{text-decoration:none;color:#000}.btMenuMobile{display:none !important}@media(max-width: 1120px){.wrapNav .wrapButtons{display:block;transition:all .5s;left:-100%;position:fixed;width:calc(100% - 80px);background:#fff;box-shadow:5px 5px 10px rgba(0,0,0,.35);height:100%;top:0;z-index:10;opacity:0;transition:left .4s ease 0s,opacity .3s ease;border-bottom:1px solid #ddd}.wrapNav .wrapButtons.open{left:0;opacity:1;display:block;transition:left .5s ease 0s,opacity .15s ease .15s}.wrapNav .wrapButtons button{display:block;text-align:left;margin:0;border-radius:0;width:100%;padding:18px}.wrapNav .wrapButtons button+button{border-top:1px solid #ddd}.wrapNav .wrapButtons .wrapSubmenu{position:static !important;transform:none !important}.wrapNav .wrapButtons .wrapSubmenu div:before{display:none}.wrapNav .wrapButtons .wrapSubmenu>div{box-shadow:none !important;padding:4px 0 4px 12px !important;margin-top:0 !important;border-bottom:1px solid #ddd}.btMenuMobile{display:inline-block;color:#009b7f;padding:7px 0 7px 20px;font-size:24px !important;width:25px;display:block !important}}
.slick-slider{outline:none}.slick-slide{width:300px}.slick-dots{list-style-type:none;display:flex !important;justify-content:center;padding:0;margin:10px 0 40px}.slick-dots button{width:10px;height:10px;margin:0 6px;-webkit-appearance:none;-moz-appearance:none;appearance:none;border-radius:50%;background:#009b7f;border:none;text-indent:-100px;overflow:hidden;font-size:0;padding:0;outline:none}.slick-dots .slick-active button{background:#0355b6}.slick-arrow{position:absolute;top:calc(50% - 10px);font-size:0;width:40px;height:40px;border:0;background:none;-webkit-appearance:none;-moz-appearance:none;appearance:none;outline:none;z-index:2;cursor:pointer}.slick-arrow:before{font-family:"Font Awesome 5 Free";font-weight:600;display:block;font-size:25px}.slick-arrow.slick-prev{left:-52px}@media(max-width: 600px){.slick-arrow.slick-prev{left:-15px}}.slick-arrow.slick-prev:before{content:""}.slick-arrow.slick-next{right:-52px}@media(max-width: 600px){.slick-arrow.slick-next{right:-15px}}.slick-arrow.slick-next:before{content:""}
