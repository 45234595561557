// @import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import url('https://use.fontawesome.com/releases/v5.15.1/css/all.css');

.slick-slider {
  outline: none;
}
.slick-slide {
  width: 300px;
}
.slick-dots {
  list-style-type: none;
  display: flex !important;
  justify-content: center;
  padding: 0;
  margin: 10px 0 40px;
  button {
    width: 10px;
    height: 10px;
    margin: 0 6px;
    appearance: none;
    border-radius: 50%;
    background: #009b7f;
    border: none;
    text-indent: -100px;
    overflow: hidden;
    font-size: 0;
    padding: 0;
    outline: none;
  }
  .slick-active {
    button {
      background: #0355b6;
    }
  }
}
.slick-arrow {
  position: absolute;
  top: calc(50% - 10px);
  font-size: 0;
  width: 40px;
  height: 40px;
  border: 0;
  background: none;
  appearance: none;
  outline: none;
  z-index: 2;
  cursor: pointer;
  &:before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    display: block;
    font-size: 25px;
  }
  &.slick-prev {
    left: -52px;
    @media (max-width: 600px) {
      left: -15px;
    }
    &:before {
      content: '\f053';
    }
  }
  &.slick-next {
    right: -52px;
    @media (max-width: 600px) {
      right: -15px;
    }
    &:before {
      content: '\f054';
    }
  }
}
