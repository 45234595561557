// @import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://use.fontawesome.com/releases/v5.15.1/css/all.css');

.wrapNav {
  padding: 22px 0;
  display: flex;
  @media (max-width: 640px) {
    .wrapButtons ~ button {
      margin: 0 !important;
    }
  }
}
.linkDefault {
  text-decoration: none;
  color: #000;
}
.btMenuMobile {
  display: none !important;
}
@media (max-width: 1120px) {
  .wrapNav {
    .wrapButtons {
      display: block;
      transition: all 0.5s;
      left: -100%;
      position: fixed;
      width: calc(100% - 80px);
      background: #ffffff;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.35);
      height: 100%;
      top: 0;
      z-index: 10;
      opacity: 0;
      transition: left 0.4s ease 0s, opacity 0.3s ease;
      border-bottom: 1px solid #ddd;
      &.open {
        left: 0;
        opacity: 1;
        display: block;
        transition: left 0.5s ease 0s, opacity 0.15s ease 0.15s;
      }
      button {
        display: block;
        text-align: left;
        margin: 0;
        border-radius: 0;
        width: 100%;
        padding: 18px;
        + button {
          border-top: 1px solid #ddd;
        }
      }
      .wrapSubmenu {
        // transform: translate3d(0px)!important;
        position: static !important;
        transform: none !important;
        div:before {
          display: none;
        }
        > div {
          box-shadow: none !important;
          padding: 4px 0 4px 12px !important;
          margin-top: 0 !important;
          border-bottom: 1px solid #ddd;
        }
      }
    }
  }
  .btMenuMobile {
    display: inline-block;
    color: #009b7f;
    padding: 7px 0 7px 20px;
    font-size: 24px !important;
    width: 25px;
    display: block !important;
  }
}
